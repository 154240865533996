import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getIndicacao() {
      return axios.get('/indicacao')
    },

    getEstatistica() {
      return axios.get('/indicacao/estatistica')
    },

  },
}
