<template>
  <div>
    <section>
      <b-row class="justify-content-center align-items-stretch match-height">
        <b-col
          lg="6"
          md="6"
          sm="8"
          class="mb-2"
        >

          <b-card
            text-variant="center"
            class="card card-congratulations shadow-lg rounded-lg p-2 h-100"
          >
            <!-- imagens -->
            <b-img
              :src="require('@/assets/images/elements/decore-left.png')"
              class="congratulations-img-left"
              fluid
              height="40px"
            />
            <b-img
              :src="require('@/assets/images/elements/decore-right.png')"
              class="congratulations-img-right"
              fluid
              height="40px"
            />
            <h1 class="mb-3 mt-2 text-white font-weight-bold">
              {{ $t('indicacao.titleCardCongratulations') }}
            </h1>

            <!-- Link Registro Button -->
            <b-card-text
              v-if="indicacao.linkRegistro"
              class="m-auto"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                size="sm"
                class="d-flex align-items-center justify-content-center text-wrap"
                @click="copyLink(indicacao.linkRegistro)"
              >
                <feather-icon
                  icon="CopyIcon"
                  size="18"
                  class="mr-2"
                />
                {{ indicacao.linkRegistro }}
              </b-button>
            </b-card-text>

            <!-- Link Landpage Button -->
            <b-card-text
              v-if="indicacao.linkLandpage"
              class="m-auto"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                size="sm"
                class="d-flex align-items-center justify-content-center mt-1 text-wrap"
                @click="copyLink(indicacao.linkLandpage)"
              >
                <feather-icon
                  icon="CopyIcon"
                  size="18"
                  class="mr-2"
                />
                {{ indicacao.linkLandpage }}
              </b-button>
            </b-card-text>
          </b-card>
        </b-col>

        <!-- Card de Estatísticas -->
        <b-col
          lg="6"
          md="6"
          sm="8"
          class="mb-2"
        >
          <b-card
            :title="$t('indicacao.titleCardStatistics')"
            class="shadow-lg rounded-lg h-100"
          >
            <b-row
              v-if="estatistica"
              class="text-center align-items-center h-100"
            >
              <b-col
                xl="4"
                sm="6"
                class="text-center"
              >
                <h4>{{ $t('indicacao.cardTextAtivos') }}</h4>
                <h1 class="font-weight-bolder my-3">
                  {{ estatistica.cadastroAtivo }}
                </h1>
              </b-col>
              <b-col
                xl="4"
                sm="6"
                class="text-center"
              >
                <h4>{{ $t('indicacao.cardTextPendentes') }}</h4>
                <h1 class="font-weight-bolder my-3">
                  {{ estatistica.cadastroPendente }}
                </h1>
              </b-col>
              <b-col
                xl="4"
                sm="6"
                class="text-center"
              >
                <h4>{{ $t('indicacao.cardTextVencidos') }}</h4>
                <h1 class="font-weight-bolder my-3">
                  {{ estatistica.cadastroVencido }}
                </h1>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BCard, BImg, BRow, BCol, BButton, BCardText,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import Ripple from 'vue-ripple-directive'
import indicacaoStoreModule from './indicacaoStoreModule'

const INDICACAO_STORE_MODULE_NAME = 'indicacao'

export default {

  components: {
    BCard,
    BImg,
    BRow,
    BCol,
    BButton,
    BCardText,
  },

  directives: {
    Ripple,
  },

  mixins: [utilsMixin],

  data() {
    return {
      indicacao: null,
      estatistica: null,
    }
  },

  computed: {
  },

  created() {
    if (!store.hasModule(INDICACAO_STORE_MODULE_NAME)) store.registerModule(INDICACAO_STORE_MODULE_NAME, indicacaoStoreModule)
    this.getIndicacao()
    this.getEstatistica()
  },

  beforeDestroy() {
    if (store.hasModule(INDICACAO_STORE_MODULE_NAME)) store.unregisterModule(INDICACAO_STORE_MODULE_NAME)
  },

  methods: {

    getEstatistica() {
      store
        .dispatch('indicacao/getEstatistica')
        .then(response => {
          this.estatistica = response.data
        })
    },

    getIndicacao() {
      store
        .dispatch('indicacao/getIndicacao')
        .then(response => {
          this.indicacao = response.data
        })
    },

    copyLink(linkText) {
      // Cria um elemento de input temporário
      const input = document.createElement('input')
      input.style.opacity = 0
      input.value = linkText
      document.body.appendChild(input)

      // Seleciona o texto no input
      input.select()
      input.setSelectionRange(0, linkText.length)

      // Executa o comando de cópia
      document.execCommand('copy')

      // Remove o input temporário
      document.body.removeChild(input)

      // Exibe uma mensagem ou feedback de sucesso
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Link copiado!',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
  },

}
</script>
